import styled from "styled-components"
import Navigator from "../components/Navigator"
import { TableCellTypes } from "../enums/TableCellTypes"
import { ReactNode, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import InputWithIcon from "../components/InputWithIcon"
import WhiteSpace from "../components/Whitespace"
import PaginationNavigator from "./PaginationNavigator"
import Icon from "../Icon";
import ExportModal from "./ExportModal"

interface ColumnType {
  label?: string,
  width?: string
}

interface CellType {
  link?: string,
  sortBy?: string | number,
  onClick?: () => unknown,
  value?:
  { type: TableCellTypes.Image, src: string, color?: string } |
  { type: TableCellTypes.Label, value: string } |
  { type: TableCellTypes.LabelDivided, value1: string, value2: string } |
  { type: TableCellTypes.Status, label: string, themeColor?: string, hexColor?: string, labelBelow?: string | null } |
  { type: TableCellTypes.Icon, src: string, color?: string } |
  { type: TableCellTypes.OpenButton } |
  { type: TableCellTypes.LabelWithIcon, value: string, src: string, iconColor?: string } |
  { type: TableCellTypes.Text, value: string },
  custom?: ReactNode
}

const Container = styled.div<{ $height: string }>`
  width: 100%;
  height: ${props => props.$height};
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  overflow-y: scroll;
`

const TableElement = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const InfoRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.gray_300};
`

const InfoLabel = styled.p`
  font-weight: 600;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_500};
`

const Row = styled.tr`
  border-bottom: 1px dashed ${props => props.theme.colors.gray_300};
  position: relative;
`

const ToolbarContainer = styled.div`
  position: absolute;
  right: 12px;
  margin-top: -38px;
  display: flex;
`

// --- Cell Presets ---

// Label
const Label = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

// LabelDivided
const LabelDividedTop = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`
const LabelDividedBottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 16px;
`

// Status
const Status = styled.p<{ $themeColor?: string, $hexColor?: string, $leaveSpaceForLableBelow?: boolean }>`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  border-radius: 40px;
  width: 70px;
  line-height: 22px;
  text-align: center;
  margin-top: ${props => props.$leaveSpaceForLableBelow ? '5px' : ''};
  background-color: ${props => props.$themeColor ? props.theme.colors[props.$themeColor] : props.$hexColor};
`
const LabelBelowStatus = styled.p`
  font-weight: 400;
  font-size: 11px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: -4px;
  width: 100px;
  margin-left: -15px;
  text-align: center;
`

// OpenButton
const OpenButtonIconContainer = styled.div`
  width: 16px;
  height: 16px;
  padding: 7px;
  background-color: #EFF2F5;
  border-radius: 6px;
  margin-right: 24px;
  float: right;
`

// Text
const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

export default function Table({ id, columns, data, height, isSearchEnabled, isSearchCtrlFEnabled, isPaginationEnabled, exportObject, triggerSearchAgain }: { id: string, columns: ColumnType[], data: CellType[][], height: string, isSearchEnabled?: boolean, isSearchCtrlFEnabled?: boolean, isPaginationEnabled?: boolean, exportObject?: any[], triggerSearchAgain?: number }) {

  const { t } = useTranslation()

  const [showing, setShowing] = useState<CellType[][]>(data)
  const [sortedColumn, setSortedColumn] = useState<number>()
  const [isSortDescending, setIsSortDescending] = useState<boolean>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageStep, setPageStep] = useState<number>(0)
  const rowRef = useRef<HTMLElement[]>([])
  const [isSearchCtrlFActive, setIsSearchCtrlFActive] = useState<boolean>(isSearchCtrlFEnabled || false)
  const [ctrlFStyleSearchResultIndexes, setCtrlFStyleSearchResultIndexes] = useState<number[]>([])
  const [ctrlFStyleSearchCurrentIndex, setCtrlFStyleSearchCurrentIndex] = useState<number>(0)

  // Sort on load according to the preferedTableSort, use column 1 if there is no preference
  useEffect(() => {
    const sortPreferenceString = localStorage.getItem('preferedTableSort')
    if (sortPreferenceString) {
      const sortPreference = JSON.parse(sortPreferenceString)
      if (sortPreference[id]) {
        handleSort(sortPreference[id].column, sortPreference[id].isDescending)
      } else {
        handleSort(1)
      }
    } else {
      handleSort(1)
    }
  }, [])

  function handleSearch(term: string) {
    if (isSearchCtrlFEnabled) {
      const result = []
      for (let i = 0; i < data.length; i++) {
        for (const cell of data[i]) {
          if (JSON.stringify({ ...cell, custom: '' }).toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
            result.push(i)
            break
          }
        }
      }
      setCtrlFStyleSearchResultIndexes(result)
      if (result.length > 0) {
        setCtrlFStyleSearchCurrentIndex(0)
        rowRef.current[result[0]].scrollIntoView({ behavior: 'smooth' })
      } else {
        setCtrlFStyleSearchCurrentIndex(-1)
      }
    } else {
      const result = []
      for (const row of data) {
        for (const cell of row) {
          if (JSON.stringify({ ...cell, custom: '' }).toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
            result.push(row)
            break
          }
        }
      }
      setShowing(result)
    }
  }

  useEffect(() => {
    if (sortedColumn) {
      console.log('triggering search again')
      handleSort(sortedColumn, !isSortDescending)
    }
  }, [triggerSearchAgain])

  function handleCtrlFStyleSearchStep(direction: 'forward' | 'backward') {
    if (ctrlFStyleSearchResultIndexes.length > 0) {
      if (direction == 'forward') {
        if (ctrlFStyleSearchCurrentIndex < ctrlFStyleSearchResultIndexes.length) {
          rowRef.current[ctrlFStyleSearchResultIndexes[ctrlFStyleSearchCurrentIndex + 1]].scrollIntoView({ behavior: 'smooth' })
          setCtrlFStyleSearchCurrentIndex(ctrlFStyleSearchCurrentIndex + 1)
        }
      } else {
        if (ctrlFStyleSearchCurrentIndex > 0) {
          rowRef.current[ctrlFStyleSearchResultIndexes[ctrlFStyleSearchCurrentIndex - 1]].scrollIntoView({ behavior: 'smooth' })
          setCtrlFStyleSearchCurrentIndex(ctrlFStyleSearchCurrentIndex - 1)
        }
      }
    }
  }

  useEffect(() => {
    if (searchTerm != '') {
      handleSearch(searchTerm)
    } else {
      setShowing(data)
    }
  }, [searchTerm])


  // Passing the isDescending param overrides the deafult reversal of direction
  function handleSort(column: number, isDescending?: boolean) {
    // Only allow sorting on columns that have sortBy key
    if (data[0][column].sortBy != undefined) {
      if (sortedColumn == column) {
        if (isDescending != undefined ? isDescending : isSortDescending) {
          data.sort((a, b) => (a[column].sortBy || '') < (b[column].sortBy || '') ? -1 : 1)
          console.log('settingfalse')
          setIsSortDescending(false)
          updateSortPreference(column, false)
        } else {
          data.sort((a, b) => (a[column].sortBy || '') > (b[column].sortBy || '') ? -1 : 1)
          console.log('settingtrue')
          setIsSortDescending(true)
          updateSortPreference(column, true)
        }
      } else if (isDescending != undefined) {
        console.log('idebelep', isDescending)
        if (isDescending) {
          data.sort((a, b) => (a[column].sortBy || '') > (b[column].sortBy || '') ? -1 : 1)
          console.log('settingtrue')
          setIsSortDescending(true)
          updateSortPreference(column, true)
        } else {
          data.sort((a, b) => (a[column].sortBy || '') < (b[column].sortBy || '') ? -1 : 1)
          console.log('settingfalse')
          setIsSortDescending(false)
          updateSortPreference(column, false)
        }
      } else {
        data.sort((a, b) => (a[column].sortBy || '') < (b[column].sortBy || '') ? -1 : 1)
        console.log('settingfalse')
        setIsSortDescending(false)
        updateSortPreference(column, false)
      }
      handleSearch(searchTerm)
      setSortedColumn(column)
      setShowing(data)
    }
  }

  function updateSortPreference(column: number, isDescending: boolean) {
    const currentPreferenceString = localStorage.getItem('preferedTableSort')
    let currentPreference = {}
    if (currentPreferenceString) {
      currentPreference = JSON.parse(currentPreferenceString) as { [key: string | number]: unknown }
    } else {
      currentPreference = {} as { [key: string | number]: unknown }
    }

    //@ts-expect-error For some reason it thinks that an object cant be indexed with a string, not sure what the problem might be
    currentPreference[id] = { column, isDescending }
    localStorage.setItem('preferedTableSort', JSON.stringify(currentPreference))
    console.log('setting')
  }

  return (
    <>
      {isSearchEnabled && <>
        <div style={{ display: 'flex' }}>
          <InputWithIcon onInput={(input) => setSearchTerm(input)} width="12vw" placeholder={t('chargers.search')} icon="general.search" iconColor="gray_600" />
          {isSearchCtrlFEnabled && searchTerm.length > 1 && <>
            <Icon path="general.filter" width="18px" height="18px" marginleft="24px" margintop="10px" color={isSearchCtrlFActive ? 'gray_600' : 'primary'} onClick={() => {
              if (!isSearchCtrlFActive) {
                setTimeout(() => {
                  rowRef.current[ctrlFStyleSearchResultIndexes[ctrlFStyleSearchCurrentIndex]].scrollIntoView({ behavior: 'smooth' })
                }, 300)
              }
              setIsSearchCtrlFActive(!isSearchCtrlFActive)
            }
            } />
          </>}
          {isSearchCtrlFActive && searchTerm.length > 1 && <>
            <div style={{ display: 'flex' }}>
              <WhiteSpace width="18px" />
              <Icon path="general.backArrow" color="gray_800" width="16px" height="16px" margintop="12px" onClick={() => handleCtrlFStyleSearchStep('backward')} />
              <WhiteSpace width="12px" />
              <p style={{ marginTop: '12px', fontSize: '12px', color: 'gray' }} >{`${ctrlFStyleSearchCurrentIndex + 1} / ${ctrlFStyleSearchResultIndexes.length}`}</p>
              <WhiteSpace width="12px" />
              <Icon path="general.forwardArrow" color="gray_800" width="16px" height="16px" margintop="12px" onClick={() => handleCtrlFStyleSearchStep('forward')} />
            </div>
          </>}
        </div>
      </>}
      <ToolbarContainer>
        {exportObject && <>
          <ExportModal data={exportObject} fileName={id} />
        </>}
      </ToolbarContainer>
      <WhiteSpace height="12px" />
      <Container $height={height}>
        <TableElement>
          <tbody>
            <InfoRow>
              {columns.map((column, columnIndex) => (
                <td key={`column${columnIndex}`} style={column.width ? { width: column.width } : {}}>
                  {column.label && (
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => data[0][columnIndex].sortBy != undefined && handleSort(columnIndex)}>
                      <InfoLabel>{column.label}</InfoLabel>
                      {data[0][columnIndex].sortBy != undefined && (
                        <Icon path={isSortDescending || false ? "general.upArrow" : "general.downArrow"} color={sortedColumn == columnIndex ? "primary" : "black"} width="12px" height="12px" margintop="10px" marginleft="6px" />
                      )}
                    </div>
                  )}
                </td>
              ))}
            </InfoRow>
            {showing.map((row, rowIndex) => (isPaginationEnabled ? rowIndex + 1 > (page - 1) * pageStep && rowIndex < page * pageStep : true) && (
              <Row key={`row${rowIndex}`} ref={row => rowRef.current[rowIndex] = row as HTMLElement} style={isSearchCtrlFEnabled && ctrlFStyleSearchResultIndexes.includes(rowIndex) && searchTerm.length > 1 ? isSearchCtrlFActive ? { backgroundColor: '#fffad1' } : {} : isSearchCtrlFEnabled ? isSearchCtrlFActive ? {} : { display: "none" } : {}}>
                {row.map((cell, cellIndex) => (
                  <td key={`row${rowIndex}cell${cellIndex}`} onClick={cell.onClick} style={cell.onClick ? { cursor: 'pointer' } : {}}>
                    <Navigator to={cell.link || ''}>
                      {cell.value && (
                        <>
                          {cell.value.type == TableCellTypes.Image && <>
                            <Icon path={cell.value.src} width="30px" height="30px" marginleft="9px" marginright="16px" margintop="2px" color={cell.value.color} />
                          </>}

                          {cell.value.type == TableCellTypes.Label && <>
                            <Label>{cell.value.value}</Label>
                          </>}

                          {cell.value.type == TableCellTypes.LabelDivided && <>
                            <LabelDividedTop>{cell.value.value1}</LabelDividedTop>
                            <LabelDividedBottom>{cell.value.value2}</LabelDividedBottom>
                          </>}

                          {cell.value.type == TableCellTypes.Status && <>
                            {cell.value.labelBelow ? <>
                              <Status $themeColor={cell.value.themeColor} $hexColor={cell.value.hexColor} $leaveSpaceForLableBelow>{cell.value.label}</Status>
                              <LabelBelowStatus>{cell.value.labelBelow}</LabelBelowStatus>
                            </> : <>
                              <Status $themeColor={cell.value.themeColor} $hexColor={cell.value.hexColor}>{cell.value.label}</Status>
                            </>}
                          </>}

                          {cell.value.type == TableCellTypes.Icon && <>
                            <Icon path={cell.value.src} color={cell.value.color} width="10px" height="10px" marginleft="9px" marginright="16px" />
                          </>}

                          {cell.value.type == TableCellTypes.OpenButton && <>
                            <OpenButtonIconContainer>
                              <Icon path="general.forwardArrow" width="100%" height="100%" />
                            </OpenButtonIconContainer>
                          </>}

                          {cell.value.type == TableCellTypes.LabelWithIcon && <>
                            <div style={{ display: 'flex' }}>
                              <Icon path={cell.value.src} width="20px" height="20px" marginright="4px" margintop="6px" color={cell.value.iconColor} />
                              <Label>{cell.value.value}</Label>
                            </div>
                          </>}

                          {cell.value.type == TableCellTypes.Text && <>
                            <Text>{cell.value.value}</Text>
                          </>}
                        </>
                      )}
                      {!cell.value && cell.custom && cell.custom}
                    </Navigator>
                  </td>
                ))}
              </Row>
            ))}
          </tbody>
        </TableElement>
      </Container>
      {showing && isPaginationEnabled && <>
        <WhiteSpace height="16px" />
        <PaginationNavigator notAbsolute width="100%" numberOfEntries={showing.length} page={page} setPage={setPage} step={pageStep} setStep={setPageStep} />
      </>}
    </>
  )
}