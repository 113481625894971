import { getAuth } from "firebase/auth"
import { useEffect, useState } from "react"
import fbConf from "../FirebaseConf"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { t } from "i18next"

const TimelockLabel = styled.p<{ $isMenuOpen: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_600};
  margin: 0px;
  margin-left: 8px;
  width: ${props => props.$isMenuOpen ? 'auto' : '1px'};
`

export default function SignOutTimer({ isMenuOpen, triggerReset }: { isMenuOpen: boolean, triggerReset: number }) {
  const navigate = useNavigate()
  const [timelockSeconds, setTimelockSeconds] = useState<number>(60 * 60)

  useEffect(() => {
    const timelockInterval = setTimeout(() => {
      if (timelockSeconds <= 0) {
        handleSignOut()
      } else {
        setTimelockSeconds(timelockSeconds - 1)
      }
    }, 1000)

    return () => clearTimeout(timelockInterval)
  }, [timelockSeconds])

  /*useEffect(() => {
    setTimelockSeconds(60 * 60)
  }, [location.pathname])*/

  useEffect(() => {
    setTimelockSeconds(60 * 60)
  }, [triggerReset])

  async function handleSignOut() {
    await getAuth(fbConf).signOut()
    localStorage.removeItem('token')
    navigate('/login')
  }

  return (
    <TimelockLabel $isMenuOpen={isMenuOpen}>{`${isMenuOpen ? `${t('app.timelock')}:` : ''} ${Math.floor(timelockSeconds / 60)}:${String(timelockSeconds % 60).padStart(2, '0')}`}</TimelockLabel>
  )
}