import { useState } from "react"
import ButtonWithIcon from "../components/ButtonWithIcon"
import Modal from "../components/Modal"
import styled from "styled-components"
import WhiteSpace from "../components/Whitespace"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import downloadObjectInBrowser from "../utils/downloadObjectInBrowser"
import writeXlsxFile from 'write-excel-file'

const SmallModalContainer = styled.div`
  padding: 24px;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ExportModalLabel = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.colors.gray_900};
`

const ExportModalSubLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
`

const ExportModalFormatLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

export default function ExportModal({ data, fileName, modalOnly, handleAfterDone }: { data: any[], fileName: string, modalOnly?: boolean, handleAfterDone?: () => void }) {
  const { t } = useTranslation()

  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(modalOnly || false)
  const [exportSelectedFormats, setExportSelectedFormats] = useState<string[]>(['.XLSX'])

  function handleToggleExportFormats(format: string) {
    const newExportSelectedFormats = [...exportSelectedFormats]
    if (newExportSelectedFormats.includes(format)) {
      newExportSelectedFormats.splice(newExportSelectedFormats.indexOf(format), 1)
    } else {
      newExportSelectedFormats.push(format)
    }
    setExportSelectedFormats(newExportSelectedFormats)
  }

  async function handleExport() {
    if (exportSelectedFormats.includes('.JSON')) {
      downloadObjectInBrowser(data, `${fileName} ${new Date().toLocaleString('hu')}`)
    }

    if (exportSelectedFormats.includes('.XLSX')) {
      // Normalize data so all objects have the same keys
      const allKeys = [...new Set(data.flatMap(Object.keys))]
      data = data.map(item => Object.fromEntries(allKeys.map(key => [key, item[key] ?? '-'])))

      const result = []
      result.push(Object.keys(data[0]).map(value => ({ value })))
      for (const row of data) {
        // @ts-expect-error Does not know the type of value here, but thats not imporant, we can still .toString() it
        result.push(Object.values(row).map(value => ({ value: value.toString() || '-' })))
      }
      
      // @ts-expect-error Does not know the type of value here, but thats not imporant, we can still .toString() it
      const columns = Object.values(data[0]).map(value => ({ width: Math.max(value.toString().length + 5, 10) }))
      await writeXlsxFile(result, { columns, fileName: `${fileName} ${new Date().toLocaleString('hu')}.xlsx` })
    }

    setIsExportModalOpen(false)
    setExportSelectedFormats(['.XLSX'])
    handleAfterDone && handleAfterDone()
  }

  return (
    <>
      {!modalOnly && (
        <ButtonWithIcon icon="general.export" label={t('chargerLogs.export')} onClick={() => setIsExportModalOpen(true)} backgroundColor="white" />
      )}
      {isExportModalOpen && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsExportModalOpen(false)} />
            </ModalCloseButtonContainer>
            <ExportModalLabel>{t('general.export')}</ExportModalLabel>
            <ExportModalSubLabel>*{t('chargerLogs.youCanChooseMultipleFileTypes')}</ExportModalSubLabel>
            <WhiteSpace height="8px" />
            {['.XLSX', '.JSON'].map(format => (
              <div key={format} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleToggleExportFormats(format)}>
                <Icon path={exportSelectedFormats.includes(format) ? "general.checkboxFull" : "general.checkboxEmpty"} width="16px" height="16px" margintop="14px" marginright="8px" />
                <ExportModalFormatLabel><b>{format}</b> {t('chargerLogs.fileExport')}</ExportModalFormatLabel>
              </div>
            ))}
            <WhiteSpace height="16px" />
            <div style={{ width: "40%" }}>
              <ButtonWithIcon icon="general.export" iconColor="white" label={t('chargerLogs.export')} onClick={handleExport} />
            </div>
          </SmallModalContainer>
        </Modal>
      )}
    </>
  )
}