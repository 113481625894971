import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import { useEffect, useState } from "react"
import Comm from "../../Comm"
import ChargerType from "../../types/ChargerType"
import Dropdown from "../../components/Dropdown"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import Modal from "../../components/Modal"
import Icon from "../../Icon"
import Button from "../../components/Button"
import InputWithLabel from "../../components/InputWithLabel"
import Switch from "../../components/Switch"
import Select from "../../components/Select"
import { useOutletContext } from "react-router-dom"
import LoaderDots from "../../components/LoaderDots"
import InputWithIcon from "../../components/InputWithIcon"
import { child, get, getDatabase, ref } from "firebase/database"
import fbConf from "../../FirebaseConf"
import readXlsxFile from 'read-excel-file'
import writeXlsxFile from "write-excel-file"

interface UserType {
  access_level: number,
  email: string,
  expiry_date: number,
  name: string,
  user_id: string
}

interface RfidType {
  email: string,
  enabled: boolean,
  id: string,
  name: string,
  last_use: number
}

const ToolbarContaier = styled.div`
  position: absolute;
  z-index: 10;
  right: 110px;
  display: flex;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const ModalContainer = styled.div`
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 65px;
  padding-bottom: 65px;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const SwitchLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin-top: 8px;
  color: ${props => props.theme.colors.gray_900};
`

const EmptyTableLabel = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.gray_900};
  margin-top: 48px;
  position: absolute;
`

const CopyRfidsModalChargerLabel = styled.p<{ $isOffline?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$isOffline ? props.theme.colors.gray_600 : props.theme.colors.gray_900};
`

const ModalButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 10px);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`

const ModalButton = styled.p<{ $hasBg?: boolean }>`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.$hasBg ? props.theme.colors.white : props.theme.colors.gray_500};
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 16px;
  width: 20%;
  text-align: center;
  border-radius: 12px;
  float: right;
  background-color: ${props => props.$hasBg ? props.theme.colors.dark : 'none'};
  cursor: pointer;
`

const FileDropContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.primaryLight};
  border: 1px dashed ${props => props.theme.colors.primary};
  cursor: pointer;
`

const FileDropLabel = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  width: 60vw;
  text-align: center;
  margin: 0px;
`

export default function UsersRfid({ charger, isAdmin }: { charger: ChargerType, isAdmin: boolean }) {
  const { t } = useTranslation()
  const { theme } = useOutletContext<{ theme: string }>()

  const [users, setUsers] = useState<UserType[] | null>()
  const [rfids, setRfids] = useState<RfidType[] | null>()
  const [lastUsersRefresh, setLastUsersRefresh] = useState<string>('')
  const [lastRfidsRefresh, setLastRfidsRefresh] = useState<string>('')

  //const [isEditingUser, setIsEditingUser] = useState<UserType | null>()

  const [isEditingRfid, setIsEditingRfid] = useState<boolean>(false)
  const [isAddingRfid, setIsAddingRfid] = useState<boolean>(false)
  const [rfidIdInput, setRfidIdInput] = useState<string>()
  const [rfidNameInput, setRfidNameInput] = useState<string>()
  const [rfidEnabledInput, setRfidEnabledInput] = useState<boolean>()
  const [rfidEmailInput, setRfidEmailInput] = useState<string>()

  const [isAddingUser, setIsAddingUser] = useState<boolean>(false)
  const [userEmailInput, setUserEmailInput] = useState<string>()
  const [userNameInput, setUserNameInput] = useState<string>()
  const [userAccessLevelInput, setUserAccessLevelInput] = useState<2 | 3>(2)
  const [isAddingUserError, setIsAddingUserError] = useState<boolean>(false)
  const [isAddingUserLoading, setIsAddingUserLoading] = useState<boolean>(false)

  const [isCopyingRfid, setIsCopyingRfid] = useState<boolean>(false)
  const [chargersToCopyRfidsTo, setChargersToCopyRfidsTo] = useState<ChargerType[]>()
  const [isCopyingRfidsInProcess, setIsCopyingRfidsInProcess] = useState<boolean>(false)
  const [chargersThatRfidsAreCopiedTo, setChargersThatRfidsAreCopiedTo] = useState<string[]>()
  const [chargersToShowAfterSearch, setChargersToShowAfterSearch] = useState<ChargerType[]>()
  const [chargers, setChargers] = useState<ChargerType[]>([])

  const [isUploadingRfidsFromFile, setIsUploadingRfidsFromFile] = useState<boolean>(false)
  const [uploadingRfidsList, setUploadingRfidsList] = useState<RfidType[] | null>()


  useEffect(() => {
    async function fetchUsersAndRfids() {
      await fetchUsers()
      await fetchRfids()
    }
    fetchUsersAndRfids()
  }, [])

  async function fetchChargers() {
    let result
    if (isAdmin) {
      const response = await Comm('/api-charger/chargers', {})
      if (response.status != 200) { return }
      result = await response.json()

    } else {
      const response = await Comm('/api-charger/user/chargers', {})
      if (response.status != 200) { return }
      result = await response.json()

      if (result.length > 0) {
        // Get users chargers from firebase to get the firebase charger name
        const firebaseChargerNamesResponse = await get(child(ref(getDatabase(fbConf)), `voltie/chargerusers/${result[0].userId}/chargers`))
        const firebaseChargerNames = await firebaseChargerNamesResponse.val()
        for (const charger of result) {
          charger.localName = firebaseChargerNames[charger.chargerId]?.name || charger.localName
        }
      }
    }

    // Fetch and set the list of online chargers
    const onlineChargersResponse = await Comm('/api-websocket/online', {})
    const onlineChargers = await onlineChargersResponse.json()
    for (const charger of result) {
      charger.online = onlineChargers.includes(charger.chargerId)
    }

    setChargers(result)
    setChargersToShowAfterSearch(result)
  }

  async function fetchUsers() {
    try {
      const response = await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 46 })
      })
      const result = await response.json()
      setUsers(result.users)
      setLastUsersRefresh(new Date().toString().split(' ')[4])
    } catch (e) { console.log(e, 'Charger probably offline') }
  }

  async function fetchRfids() {
    try {
      const response = await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 23 })
      })
      const result = await response.json()
      setRfids(result.rfid_list)
      setLastRfidsRefresh(new Date().toString().split(' ')[4])
    } catch (e) { console.log(e, 'Charger probably offline') }
  }

  function handleRefreshUsers() {
    setUsers(null)
    fetchUsers()
  }

  function handleRefreshRfids() {
    setRfids(null)
    fetchRfids()
  }

  function startEditRfid(rfid: RfidType) {
    setRfidIdInput(rfid.id)
    setRfidNameInput(rfid.name)
    setRfidEmailInput(rfid.email)
    setRfidEnabledInput(rfid.enabled)
    setIsEditingRfid(true)
  }

  async function saveEditRfid() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 25, "rfid_tag": { "id": rfidIdInput, "name": rfidNameInput, "enabled": rfidEnabledInput, "email": rfidEmailInput } })
    })
    setRfidIdInput('')
    setRfidNameInput('')
    setRfidEmailInput('')
    setRfidEnabledInput(true)
    setIsEditingRfid(false)
    handleRefreshRfids()
  }

  function startAddRfid() {
    setRfidIdInput('')
    setRfidNameInput('')
    setRfidEmailInput('')
    setRfidEnabledInput(true)
    setIsAddingRfid(true)
  }

  async function saveAddRfid() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 54, "rfid_tag": { "id": rfidIdInput, "name": rfidNameInput, "enabled": rfidEnabledInput } })
    })
    setRfidIdInput('')
    setRfidNameInput('')
    setRfidEmailInput('')
    setRfidEnabledInput(true)
    setIsAddingRfid(false)
    handleRefreshRfids()
  }

  async function deleteRfid(rfid: RfidType) {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 26, "id": rfid.id })
    })
    handleRefreshRfids()
  }

  function toggleRfidIsSelectedInRfidCopyModal(chargerId: string) {
    if (chargersToCopyRfidsTo?.some(c => c.chargerId === chargerId)) {
      setChargersToCopyRfidsTo(chargersToCopyRfidsTo?.filter(c => c.chargerId !== chargerId))
    } else {
      const charger = chargers.find(c => c.chargerId === chargerId)
      if (charger) {
        setChargersToCopyRfidsTo(chargersToCopyRfidsTo ? [...chargersToCopyRfidsTo, charger] : [charger])
      }
    }
  }

  async function copyRfidsToSelectedChargers() {
    if (!chargersToCopyRfidsTo || chargersToCopyRfidsTo.length === 0 || !rfids || rfids.length === 0) return

    const rfidList = rfids.map(rfid => ({
      id: rfid.id,
      name: rfid.name,
      enabled: rfid.enabled,
      //email: rfid.email
    }))

    setIsCopyingRfidsInProcess(true)

    const doneCopyingChargerList = []
    for (const charger of chargersToCopyRfidsTo) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ msg_type: 55, rfid_list: rfidList })
      })

      doneCopyingChargerList.push(charger.chargerId)
      setChargersThatRfidsAreCopiedTo(doneCopyingChargerList)
    }
  }

  async function convertExcelFileToObject(file: File) {
    // Only .xlsx files are allowed
    if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      const xlsxAsArrayMatrix = await readXlsxFile(file)
      // Remove the first row which is the header
      xlsxAsArrayMatrix.shift()
      const xlsxAsRfidObjectArray = xlsxAsArrayMatrix.map(row => ({ id: row[0], name: row[1], enabled: row[2] === "true", email: row[3] } as RfidType))
      setUploadingRfidsList(xlsxAsRfidObjectArray)
    }
  }

  function handleDropFilesToUploadRfidsFromFileModal(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer?.files) { convertExcelFileToObject(Array.from(e.dataTransfer?.files)[0]) }
  }

  async function handleAddFileToUploadingFilesWithFilePicker() {
    // @ts-expect-error The TS filesystem api types a crazy broken... It thinks that showOpenFilePicker does not exists
    const fileHandler = await window.showOpenFilePicker({ multiple: false })
    convertExcelFileToObject(await fileHandler[0].getFile())
  }

  async function saveRfidsFromFileToCharger() {
    if (!uploadingRfidsList || uploadingRfidsList.length === 0) return

    const rfidList = uploadingRfidsList.map(rfid => ({
      id: rfid.id.toString(),
      name: rfid.name.toString(),
      enabled: rfid.enabled,
      // Not writing that in there yet, because in will just set enabled to false if the user does not exist
      //email: rfid.email.toString()
    }))

    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ msg_type: 55, rfid_list: rfidList })
    })

    setIsUploadingRfidsFromFile(false)
    setUploadingRfidsList(null)
    handleRefreshRfids()
  }

  async function downloadRfidUploadExampleFile() {
    const example = [
      [{ value: 'id' }, { value: 'name' }, { value: 'enabled' }, { value: 'email' }],
      [{ value: '111111' }, { value: 'Example RFID 1' }, { value: 'true' }, { value: 'one@email.com' }],
      [{ value: '222222' }, { value: 'Example RFID 2' }, { value: 'false' }, { value: 'two@email.com' }],
    ]
    const columns = Array.from({ length: 4 }, () => ({ width: 20 }))
    await writeXlsxFile(example, { columns, fileName: "RFID example.xlsx" })
  }

  function startAddUser() {
    //setIsAddingUserLoading(true)
    setUserEmailInput('')
    setUserNameInput('')
    setUserAccessLevelInput(2)
    setIsAddingUserError(false)
    setIsAddingUser(true)
  }

  async function saveAddUser() {
    setIsAddingUserLoading(true)
    let response
    if (isAdmin) {
      //TODO: Implement this
      alert("Adminoknak még nem működik, Imi dolgozik az endpointon, szólok neki, hogy siessen XD")
      response = await Comm('/api-admin/chargeruser/admin/access/add', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "chargerId": charger.chargerId, "email": userEmailInput, "name": userNameInput, "userId": charger.userId })
      })
    } else {
      response = await Comm('/api-admin/chargeruser/access/add', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "chargerId": charger.chargerId, "access_level": userAccessLevelInput, "email": userEmailInput, "name": userNameInput })
      })
    }
    if (response.ok) {
      setUserEmailInput('')
      setUserNameInput('')
      setUserAccessLevelInput(2)
      setIsAddingUser(false)
      setIsAddingUserError(false)
      handleRefreshUsers()
    } else {
      setIsAddingUserError(true)
    }
    setIsAddingUserLoading(false)
  }

  async function deleteUser(user: UserType) {
    if (isAdmin) {
      //TODO: Implement this
      alert("Adminoknak még nem működik, Imi dolgozik az endpointon, szólok neki, hogy siessen XD")
      await Comm('/api-admin/chargeruser/admin/access/delete', {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "chargerId": charger.chargerId, "email": user.email, "userId": charger.userId })
      })
    } else {
      await Comm('/api-admin/chargeruser/access/delete', {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "chargerId": charger.chargerId, "email": user.email })
      })
    }
    handleRefreshUsers()
  }

  return (
    <>
      {isEditingRfid && <>
        <Modal>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsEditingRfid(false)} />
            </ModalCloseButtonContainer>
            <Icon path="chargers.rfidTag" width="96px" height="96px" marginleft="calc((100% - 96px) / 2)" />
            <WhiteSpace height="30px" />
            <InputWithLabel label={t('charger.usersrfids.rfidId')} width={"16vw"} value={rfidIdInput || ''} onInput={() => { }} />
            <WhiteSpace height="8px" />
            <InputWithLabel label={t('charger.usersrfids.rfidName')} width={"16vw"} value={rfidNameInput || ''} onInput={(e) => setRfidNameInput(e)} />
            <WhiteSpace height="8px" />
            <Select theme={theme} width={"calc(100% - 24px)"} options={users ? [rfidEmailInput || '', ...users.map((user) => user.email)] : []} handleSelect={(e) => setRfidEmailInput(e)} isAbsolute />
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <SwitchLabel>{`${t('charger.enabled')}:`}</SwitchLabel>
              <div style={{ position: 'absolute', right: '88px' }}>
                <Switch toggled={rfidEnabledInput || false} setToggled={setRfidEnabledInput} />
              </div>
            </div>
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <Button onclick={() => setIsEditingRfid(false)} label={t('charger.cancel')} width='calc(8vw - 8px)' bgcolor="transparent" labelcolor="black" />
              <WhiteSpace width="8px" />
              <Button onclick={saveEditRfid} label={t('charger.save')} width='8vw' />
            </div>
          </ModalContainer>
        </Modal>
      </>}

      {isAddingRfid && <>
        <Modal>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsAddingRfid(false)} />
            </ModalCloseButtonContainer>
            <Icon path="chargers.rfidTag" width="96px" height="96px" marginleft="calc((100% - 96px) / 2)" />
            <WhiteSpace height="30px" />
            <InputWithLabel label={t('charger.usersrfids.rfidId')} width={"16vw"} value={rfidIdInput || ''} onInput={(e) => setRfidIdInput(e)} />
            <WhiteSpace height="8px" />
            <InputWithLabel label={t('charger.usersrfids.rfidName')} width={"16vw"} value={rfidNameInput || ''} onInput={(e) => setRfidNameInput(e)} />
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <SwitchLabel>{`${t('charger.enabled')}:`}</SwitchLabel>
              <div style={{ position: 'absolute', right: '88px' }}>
                <Switch toggled={rfidEnabledInput || false} setToggled={setRfidEnabledInput} />
              </div>
            </div>
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <Button onclick={() => setIsAddingRfid(false)} label={t('charger.cancel')} width='calc(8vw - 8px)' bgcolor="transparent" labelcolor="black" />
              <WhiteSpace width="8px" />
              <Button onclick={saveAddRfid} label={t('charger.save')} width='8vw' />
            </div>
          </ModalContainer>
        </Modal>
      </>}

      {isCopyingRfid && <>
        <Modal align="right">
          <div style={{ height: '85vh', padding: '24px' }}>
            {!isCopyingRfidsInProcess && <>
              <InputWithIcon onInput={(input) => setChargersToShowAfterSearch(chargers?.filter(charger => (`${charger.chargerId} ${charger.localName}`).includes(input)))} width="20vw" placeholder={t('chargers.search')} icon="general.search" iconColor="gray_600" />
              <WhiteSpace height="12px" />
            </>}
            {!chargersToShowAfterSearch && <LoaderDots />}
            <div style={{ overflowY: 'scroll', height: 'calc(85vh - 90px)' }}>

              {!isCopyingRfidsInProcess ? <>

                {chargersToShowAfterSearch && chargersToShowAfterSearch.map(charger => chargersToCopyRfidsTo?.includes(charger) && (
                  <div key={charger.chargerId} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => toggleRfidIsSelectedInRfidCopyModal(charger.chargerId)}>
                    <Icon width="16px" height="16px" margintop="11px" path='general.checkboxFull' />
                    <WhiteSpace width="12px" />
                    <div style={{ display: 'flex' }}>
                      <CopyRfidsModalChargerLabel style={{ width: '140px' }}>{charger.chargerId}</CopyRfidsModalChargerLabel>
                      <CopyRfidsModalChargerLabel>{charger.localName}</CopyRfidsModalChargerLabel>
                    </div>
                  </div>
                ))}

                {chargersToShowAfterSearch && chargersToShowAfterSearch.map(charger => !chargersToCopyRfidsTo?.includes(charger) && (
                  <div key={charger.chargerId} style={charger.online ? { display: 'flex', cursor: 'pointer' } : { display: 'flex', cursor: 'default' }} onClick={() => charger.online && toggleRfidIsSelectedInRfidCopyModal(charger.chargerId)}>
                    <Icon width="16px" height="16px" margintop="11px" path='general.checkboxEmpty' />
                    <WhiteSpace width="12px" />
                    <div style={{ display: 'flex' }}>
                      <CopyRfidsModalChargerLabel $isOffline={!charger.online} style={{ width: '140px' }}>{charger.chargerId}</CopyRfidsModalChargerLabel>
                      <CopyRfidsModalChargerLabel $isOffline={!charger.online}>{charger.localName}</CopyRfidsModalChargerLabel>
                    </div>
                    {!charger.online && <>
                      <WhiteSpace width="12px" />
                      <p style={{ backgroundColor: '#f0f0f0', padding: '1px 6px', borderRadius: '4px', color: '#888', fontSize: '12px' }}>{t('charger.offline')}</p>
                    </>}
                  </div>
                ))}
              </> : <>

                {chargersToCopyRfidsTo && chargersToCopyRfidsTo.map(charger => (
                  <div key={charger.chargerId} style={{ display: 'flex' }}>
                    {chargersThatRfidsAreCopiedTo?.includes(charger.chargerId) ? <>
                      <Icon width="16px" height="16px" margintop="11px" path='general.check' color="green" />
                      <WhiteSpace width="8px" />
                      <p style={{ backgroundColor: '#f0f0f0', padding: '1px 6px', borderRadius: '4px', color: '#888', fontSize: '12px' }}>{t('charger.success')}</p>
                    </> : <>
                      <WhiteSpace width="16px" />
                    </>}
                    <WhiteSpace width="12px" />
                    <div style={{ display: 'flex' }}>
                      <CopyRfidsModalChargerLabel style={{ width: '140px' }}>{charger.chargerId}</CopyRfidsModalChargerLabel>
                      <CopyRfidsModalChargerLabel>{charger.localName}</CopyRfidsModalChargerLabel>
                    </div>
                  </div>
                ))}
              </>}
            </div>
            <ModalButtonContainer>
              {!isCopyingRfidsInProcess && (
                <ModalButton onClick={copyRfidsToSelectedChargers} $hasBg>{t('charger.copy')}</ModalButton>
              )}
              <ModalButton onClick={() => { setIsCopyingRfid(false); setChargersToCopyRfidsTo([]); setChargersThatRfidsAreCopiedTo([]); setIsCopyingRfidsInProcess(false); setChargersToShowAfterSearch(chargers) }}>{isCopyingRfidsInProcess ? t('charger.close') : t('charger.cancel')}</ModalButton>
            </ModalButtonContainer>
          </div>
        </Modal>
      </>}

      {isUploadingRfidsFromFile && <>
        <Modal>
          <div style={{ width: '60vw', padding: '24px', paddingTop: uploadingRfidsList ? '12px' : '24px' }}>
            {!uploadingRfidsList && <>
              <ModalCloseButtonContainer>
                <Icon path="general.x" width="20px" height="20px" onClick={() => setIsUploadingRfidsFromFile(false)} />
              </ModalCloseButtonContainer>
              <WhiteSpace height="32px" />
              <FileDropContainer onDrop={(e) => handleDropFilesToUploadRfidsFromFileModal(e as unknown as DragEvent)} onDragOver={(e) => e.preventDefault()} onClick={handleAddFileToUploadingFilesWithFilePicker}>
                <WhiteSpace height="16px" />
                <Icon path="chargerGroups.fileDropUpload" width="48px" height="48px" marginleft="calc((100% - 48px) / 2)" />
                <WhiteSpace height="8px" />
                <FileDropLabel>{t('charger.usersrfids.dropXlsxFileOfClickToBrowse')}</FileDropLabel>
                <WhiteSpace height="16px" />
              </FileDropContainer>
              <WhiteSpace height="16px" />
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <ButtonWithIcon icon="general.download" label={t('charger.usersrfids.downloadExampleFile')} onClick={downloadRfidUploadExampleFile} backgroundColor="dark" iconColor="white" />
              </div>
            </>}

            {uploadingRfidsList && uploadingRfidsList.length > 0 && <>
              <Table id="UsersRfids_RfidUploadFromFile" height="calc(85vh - 126px)" columns={[
                { width: '30px' },
                { label: 'NO', width: '50px' },
                { label: t('charger.rfidNumber'), width: '18%' },
                { label: t('charger.rfidName'), width: '24%' },
                { label: t('charger.active'), width: '15%' },
                { label: t('charger.user') },
              ]} data={uploadingRfidsList.map((rfid, index) => [
                {
                  value: {
                    type: TableCellTypes.Image,
                    src: 'chargers.rfidTag'
                  }
                }, {
                  sortBy: index,
                  value: {
                    type: TableCellTypes.Label,
                    value: index + 1 + '.'
                  }

                }, {
                  sortBy: rfid.id,
                  value: {
                    type: TableCellTypes.Label,
                    value: rfid.id || '-'
                  }
                }, {
                  sortBy: rfid.name,
                  value: {
                    type: TableCellTypes.Label,
                    value: rfid.name || '-'
                  }
                }, {
                  sortBy: rfid.enabled ? 'a' : 'b',
                  value: {
                    type: TableCellTypes.Status,
                    label: rfid.enabled ? t('charger.active') : t('charger.inactive'),
                    themeColor: rfid.enabled ? 'success' : 'gray_400'
                  }
                }, {
                  sortBy: rfid.email || '',
                  value: {
                    type: TableCellTypes.Label,
                    value: rfid.email || '-'
                  }
                }
              ])} />
            </>}

            {uploadingRfidsList && <>
              <WhiteSpace height="38px" />
              <ModalButtonContainer>
                <ModalButton onClick={saveRfidsFromFileToCharger} $hasBg>{t('charger.save')}</ModalButton>
                <ModalButton onClick={() => { setIsUploadingRfidsFromFile(false); setUploadingRfidsList(null) }}>{t('charger.cancel')}</ModalButton>
              </ModalButtonContainer>
            </>}
          </div>
        </Modal>
      </>}

      {isAddingUser && <>
        <Modal>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsAddingUser(false)} />
            </ModalCloseButtonContainer>
            <Icon path="chargers.user" width="96px" height="96px" marginleft="calc((100% - 96px) / 2)" />
            <WhiteSpace height="30px" />
            <InputWithLabel label={t('charger.usersrfids.userEmail')} width={"16vw"} value={userEmailInput || ''} onInput={(e) => setUserEmailInput(e)} />
            <WhiteSpace height="8px" />
            <InputWithLabel label={t('charger.usersrfids.userName')} width={"16vw"} value={userNameInput || ''} onInput={(e) => setUserNameInput(e)} />
            <WhiteSpace height="8px" />
            <Select theme={theme} width={"calc(100% - 24px)"} options={["User", "Admin"]} handleSelect={(e) => setUserAccessLevelInput(e == "User" ? 2 : 3)} isAbsolute />
            <WhiteSpace height="24px" />
            {isAddingUserError && <>
              <p style={{ fontSize: '12px', color: "#FD4E4E", width: '100%', textAlign: 'center' }}>{t('charger.usersrfids.unknownEmailAddress')}</p>
            </>}
            <div style={{ display: 'flex' }}>
              <Button onclick={() => setIsAddingUser(false)} label={t('charger.cancel')} width='calc(8vw - 8px)' bgcolor="transparent" labelcolor="black" />
              <WhiteSpace width="8px" />
              <Button onclick={saveAddUser} label={isAddingUserLoading ? <LoaderDots /> : t('charger.save')} width='8vw' />
            </div>
          </ModalContainer>
        </Modal>
      </>}


      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.users')} />
      <ToolbarContaier>
        <div>
          <ButtonWithIcon icon="general.plus" label={t('charger.usersrfids.addNewUser')} onClick={startAddUser} iconColor="white" backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('logs.refresh')} onClick={handleRefreshUsers} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastUsersRefresh}</RefreshTimerLabel>
        </div>
      </ToolbarContaier>
      {users && users.length > 0 ? <>
        <Table id="UsersRfids_Users" height="calc((90vh - 345px) / 2)" isSearchEnabled={true} isPaginationEnabled={true} exportObject={users} columns={[
          { width: '30px' },
          { label: t('charger.userEmail'), width: '20%' },
          { label: t('charger.nameUserUid'), width: '20%' },
          { label: t('charger.permissionLevel'), width: '20%' },
          { label: t('charger.created'), width: '20%' },
          { label: t('charger.signedIn') },
          { width: '20px' }
        ]} data={users.map(user => [
          {
            value: {
              type: TableCellTypes.Image,
              src: 'chargers.user'
            }
          }, {
            sortBy: user.email,
            value: {
              type: TableCellTypes.Label,
              value: user.email || '-'
            }
          }, {
            sortBy: user.name,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: user.name,
              value2: user.user_id
            }
          }, {
            sortBy: user.access_level,
            value: {
              type: TableCellTypes.Status,
              label: t(`charger.userAccessLevel.${user.access_level}`),
              themeColor: (() => {
                switch (user.access_level) {
                  case 0: return 'gray_400'
                  case 1: return 'gray_400'
                  case 2: return 'primary'
                  case 3: return 'gray_900'
                  case 4: return 'gray_400'
                  case 5: return 'gray_400'
                  case 6: return 'gray_400'
                  case 7: return 'success'
                  default: return 'success'
                }
              })()
            }
          }, {
            value: {
              type: TableCellTypes.Label,
              value: '-'
            }
          }, {
            value: {
              type: TableCellTypes.Label,
              value: '-'
            }
          }, {
            custom: <Dropdown handleChoose={(value) => value == t('charger.delete') && deleteUser(user)} title={{ icon: "general.more", label: '' }} options={[{ icon: "general.trash", label: t('charger.delete'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
          }
        ])} />
      </> : users && users.length == 0 ? <>
        <EmptyTableLabel>{t(`charger.noUsers`)}</EmptyTableLabel>
        <WhiteSpace height="calc((90vh - 124px) / 2)" />
      </> : <>
        <EmptyTableLabel><LoaderDots /></EmptyTableLabel>
        <WhiteSpace height="calc((90vh - 124px) / 2)" />
      </>}







      <PageTitle label={t('charger.rfidManagement')} />
      <ToolbarContaier>
        <div>
          <ButtonWithIcon icon="general.plus" label={t('charger.usersrfids.addNewRfid')} onClick={startAddRfid} iconColor="white" backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.upload" label={t('charger.usersrfids.uploadRfidsFromFile')} onClick={() => setIsUploadingRfidsFromFile(true)} iconColor="white" backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.export" label={t('charger.usersrfids.copyRfidsToChargers')} onClick={() => { fetchChargers(); setIsCopyingRfid(true) }} iconColor="white" backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('logs.refresh')} onClick={handleRefreshRfids} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRfidsRefresh}</RefreshTimerLabel>
        </div>
      </ToolbarContaier>
      {rfids && rfids.length > 0 ? <>
        <Table id="UsersRfids_Rfids" height="calc((90vh - 345px) / 2)" isSearchEnabled={true} isPaginationEnabled={true} exportObject={rfids} columns={[
          { width: '30px' },
          { label: t('charger.rfidName'), width: '20%' },
          { label: t('charger.rfidState'), width: '20%' },
          { label: t('charger.rfidNumber'), width: '20%' },
          { label: t('charger.lastUse'), width: '20%' },
          { label: t('charger.user') },
          { width: '20px' }
        ]} data={rfids.map(rfid => [
          {
            value: {
              type: TableCellTypes.Image,
              src: 'chargers.rfidTag'
            }
          }, {
            sortBy: rfid.name,
            value: {
              type: TableCellTypes.Label,
              value: rfid.name || '-'
            }
          }, {
            sortBy: rfid.enabled ? 'a' : 'b',
            value: {
              type: TableCellTypes.Status,
              label: rfid.enabled ? t('charger.active') : t('charger.inactive'),
              themeColor: rfid.enabled ? 'success' : 'gray_400'
            }
          }, {
            sortBy: rfid.id,
            value: {
              type: TableCellTypes.Label,
              value: rfid.id || '-'
            }
          }, {
            sortBy: rfid.last_use || 0,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: rfid.last_use ? new Date(rfid.last_use * 1000).toISOString().split('T')[0].replace(/-/g, '.') : '-',
              value2: rfid.last_use ? new Date(rfid.last_use * 1000).toISOString().split('T')[1].split('.')[0] : ''
            }
          }, {
            sortBy: rfid.email || '',
            value: {
              type: TableCellTypes.Label,
              value: rfid.email || '-'
            }
          }, {
            custom: <Dropdown handleChoose={(value) => value == t('charger.edit') ? startEditRfid(rfid) : deleteRfid(rfid)} title={{ icon: "general.more", label: '' }} options={[{ icon: 'general.wrench', label: t('charger.edit') }, { icon: "general.trash", label: t('charger.delete'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
          }
        ])} />
      </> : rfids && rfids.length == 0 ? <EmptyTableLabel>{t(`charger.noRfids`)}</EmptyTableLabel> : <EmptyTableLabel><LoaderDots /></EmptyTableLabel>}
    </>
  )
}